import { defineStore } from 'pinia';

export const useGamesStore = defineStore('gamesStore', () => {
  const supportedCurrencies = ref(null);
  const showBalanceDropdown = ref(false);

  function $reset() {
    supportedCurrencies.value = null;
    showBalanceDropdown.value = false;
  }

  return {
    supportedCurrencies,
    showBalanceDropdown,

    $reset,
  };
});
