<template>
  <div
    class="bg-slate-500 flex items-center justify-center border-l-2"
    :class="[
      balance.context === 'Default' ? 'border-l-blue-500' : 'border-l-orange-500',
      compact ? 'h-[28px] w-[100px]' : 'min-h-[42px] w-[150px]',
   ]"
  >
    <SvgIcon
      :icon="currencyIcon"
      width="18"
      height="18"
      class="my-auto"
    />
    <div class="ml-2 text-[12px] font-bold flex flex-col">
      <p>
        In Play
      </p>
      <BalanceValue
        v-if="balance && disableChangeBalance"
        :balance="balance"
        class="text-xs truncate transition-all text-right"
      />
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';

defineProps({
  currencyIcon: {
    type: String,
    required: true,
  },
  balance: {
    type: Object,
    required: true,
  },
  compact: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const {
  disableChangeBalance,
} = storeToRefs(uiStore);
</script>
