<template>
  <div
    v-click-outside="clickOutside"
    class="lex max-xs:static flex-col h-auto"
    :class="{ 'grow ml-2 3xs:ml-3': !compact }"
  >
    <div class="flex max-xs:bg-slate-900">
      <transition name="fade" mode="out-in">
        <BalanceInPlay
          v-if="balanceInPlay && !showBalanceDropdown"
          :currency-icon="currentCurrency"
          :balance="currentBalance"
          :compact="compact"
        />
        <div v-else class="balances flex">
          <div
            class="bg-slate-900 px-[8px] py-[2px] flex justify-center gap-1 font-bold text-xs lg:w-24 border-l-2 h-full"
            :class="[
              currentBalance?.context === 'Default' ? 'border-l-blue-500' : 'border-l-orange-500',
              { 'cursor-pointer' : balances.length && !balanceLocked },
              !compact ? 'rounded-l-md flex-col' : 'items-center',
            ]"
            @click.prevent.stop="toggleDropdown"
          >
            <div class="flex items-center">
              <SvgIcon
                :icon="currentCurrency"
                width="18"
                height="18"
                class="my-auto"
              />
              <div class="ml-[6px] mr-[4px] !text-[#B1BAD3]">{{ currentCurrency }}</div>
              <SvgIcon
                v-if="balances?.length"
                icon="arrow-down"
                width="10"
                height="7"
                :class="{ 'rotate-180': dropdown, 'opacity-50': balanceLocked }"
                class="transition"
              />
            </div>
            <div class="flex">
              <BalanceValue
                v-if="(!balanceInPlay || showBalanceDropdown) && currentBalance"
                :balance="currentBalance"
                class="text-xs truncate transition-all text-right"
              />
              <Spinner
                v-if="!balances?.length"
                :has-logo="false"
                size="2xs"
              />
            </div>
          </div>
          <ButtonButton
            v-if="currentCurrency && !compact"
            class="flex items-center justify-center !rounded-l-none !rounded-r-md"
            :disabled="isDepositDisabled || !balances.length"
            size="2xs"
            @click.prevent.stop="openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[currentBalance?.context === 'Default' ? 0 : 1])"
          >
            <SvgIcon icon="wallet-add" width="22" height="18"/>
          </ButtonButton>
        </div>
      </transition>
    </div>
    <transition name="fade-fast" mode="out-in">
      <div
        v-if="!balanceLocked && dropdown && (!balanceInPlay || showBalanceDropdown) && balances.length"
        class="absolute right-[7%] max-xs:right-0 bg-slate-900 shadow-lg shadow-black lg:shadow-transparent max-xs:w-screen max-xs:absolute max-xs:rounded-none w-[300px] py-[10px] rounded-md overflow-auto  md:max-h-[calc(100vh-70px)]"
        :class="!compact ? 'top-[60px] max-h-[calc(100vh-60px)]' : 'top-[28px] max-h-[calc(100vh-60px)]'"
      >
        <template v-if="hasVisibleBalances">
          <div
            v-for="(group, i) in groupedBalances"
            :key="`${group.context}-${i}`"
            class="px-[20px] py-2"
          >
            <h4
              class="pb-2 mb-2 font-bold text-xs border-b flex justify-between items-center"
              :class="group.context === 'Default' ? 'border-b-blue-500 bg-blue-radial' : 'border-b-orange-500 bg-orange-radial'"
            >
              {{ getGroupLabel(group.context) }} Wallet
              <ButtonButton
                theme="primary"
                size="3xs"
                @click.prevent.stop="openWallet(group.context === 'Default')"
              >
                Add Funds
              </ButtonButton>
            </h4>
            <WalletBalanceListItem
              v-for="(balance, j) in group.balances"
              :key="`${balance.name}-${j}`"
              :active="balance === currentBalance"
              :balance="balance"
              :disabled="balance.disabled"
              :can-hover="!balance.disabled"
              class="mb-1"
              @click.prevent="selectCurrency(balance)"
            />
            <div
              v-if="!group.balances.length"
              class="text-xs text-center font-bold text-orange-500"
            >
              You don't have any {{ getGroupLabel(group.context) }} balances visible, trying adjusting your settings on your
              <NuxtLink
                to="/wallet"
                class="text-white"
              >
                wallet page.
              </NuxtLink>
            </div>
          </div>
        </template>
        <div
          v-else
          class="text-xs text-center font-bold text-orange-500 px-2 py-3"
        >
          You don't have any balances visible, trying adjusting your settings on your
          <NuxtLink
            to="/wallet"
            class="text-white"
          >
            wallet page.
          </NuxtLink>
        </div>
        <div class="px-[20px] pb-2">
          <ButtonButton
            theme="grey"
            class="w-full py-3"
            size="2xs"
            :padding="false"
            @click.prevent.stop="buyCrypto()"
          >
            Deposit with
            <img src="~/assets/img/logos/moonpay.svg" class="inline-block w-20 relative -top-[2px] ml-1" alt="Moonpay">
          </ButtonButton>
          <div class="flex justify-center mt-5">
            <CheckboxSwitch
              v-model="hideZeroBalances"
              small
              class="text-xs"
              label="Hide zero balances"
            />
          </div>
        </div>
      </div>
    </transition>
    <TooltipWhatIsMeth v-if="activeCurrency === 'METH' && hover && hoverEnabled"/>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useUiStore } from '@/store/ui';
import { useBankingStore } from '@/store/banking';
import { useUserStore } from '@/store/user';
import { useGamesStore } from '@/store/games';
import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';

defineProps({
  hoverEnabled: {
    type: Boolean,
    default: false,
  },
  compact: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();
const {
  wallet,
} = storeToRefs(authStore);

const userStore = useUserStore();
const {
  userData,
} = storeToRefs(userStore);

const uiStore = useUiStore();
const {
  balanceInPlay,
  hiddenBalances,
  hideZeroBalances,
} = storeToRefs(uiStore);
const { openTxModal, } = uiStore;

const bankingStore = useBankingStore();
const {
  currentCurrency,
  activeCurrency,
  currentContext,
  activeContext,
  balances,
  activeCurrencyOverride,
  balanceOverride,
  balanceLocked,
} = storeToRefs(bankingStore);

const gamesStore = useGamesStore();
const {
  supportedCurrencies,
  showBalanceDropdown,
} = storeToRefs(gamesStore);

const hover = ref(false);
const dropdown = ref(false);

const currentBalance = computed(() => {
  const bal = balances.value.find(item => item.name === currentCurrency.value && currentContext.value === item.context);

  if (balanceOverride.value) {
    return {
      ...bal,
      value: balanceOverride.value,
    }
  }

  return bal;
});
const isDepositDisabled = computed(() => !!wallet.value?.[currentCurrency.value]?.virtual);
const groupedBalances = computed(() => {
  const groups = [
    {
      context: 'Default',
      balances: [],
    },
    {
      context: 'Custodial',
      balances: [],
    },
  ];

  for (const balance of balances.value) {
    const context = balance.context;
    const isCurrencyOverrideActive = activeCurrencyOverride.value === balance.name;
    const hasAddress = userData.value?.aliases?.EthereumAddress || userData.value?.aliases?.SolanaAddress;

    balance.disabled = (activeCurrencyOverride.value && balance.name !== activeCurrencyOverride.value) || (supportedCurrencies.value && !supportedCurrencies.value.includes(balance.name));

    if (
      (!isCurrencyOverrideActive && hiddenBalances.value[context]?.[balance.name])
      || (hideZeroBalances.value && balance.value === 0)
    ) {
      continue;
    }

    if (
      balance.context === 'Custodial'
      || ((!hasAddress || userData.value?.aliases?.EthereumAddress) && !Object.keys(balance.config.networks).includes('Solana'))
      || (userData.value?.aliases?.SolanaAddress && Object.keys(balance.config.networks).includes('Solana'))
    ) {
      groups[context === 'Default' ? 0 : 1].balances.push(balance);
    }
  }

  return groups;
});
const hasVisibleBalances = computed(() => !!groupedBalances.value.filter(group => group.balances.length));

function clickOutside() {
  dropdown.value = false;
}

function toggleDropdown() {
  if (balanceLocked.value) {
    dropdown.value = false;
    return;
  }

  if (balances.value.length) {
    dropdown.value = !dropdown.value;
  }
}

function selectCurrency(currency) {
  if (!currency.disabled) {
    activeCurrency.value = currency.name;
    activeContext.value = currency.context;
    clickOutside();
  }
}

function openWallet(isWeb3) {
  openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[isWeb3 ? 0 : 1]);
  toggleDropdown();
}

function getGroupLabel(context) {
  switch (context) {
    case 'Default':
      return 'Web3';
    default:
      return context;
  }
}

function buyCrypto() {
  openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[2]);
  toggleDropdown();
}
</script>
